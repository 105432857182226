.viewSourcesPanel {
  position: relative;
  width: 380px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  position: fixed;
  top: 0;
  right: -380px;
  z-index: 1;
  transition: 0.5s;
}

.viewSourcesPanel.active {
  right: 0;
}