.content-container {
  height: calc(100vh - 230px);
  overflow-y: scroll;
  margin-bottom: 20px;
}

.chatHistoryEmptyText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-weight: 500;
  font-size: 20pt;
  letter-spacing: 2px;
  margin-top: -30px;
}

.ragdollImg {
  width: 200px;
  height: 100px;
  margin-bottom: 20px;
  animation: ragdollImg-logo-bounce 2s ease-in-out infinite;
}

@keyframes ragdollImg-logo-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.isLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;
  font-weight: 500;
  font-size: 15pt;
  letter-spacing: 1px;
}

.ragdollLoadingImg {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  animation: ragdollLoadingImg-logo-spin 2s ease-in-out infinite;
}

@keyframes ragdollLoadingImg-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container::-webkit-scrollbar {
  width:  0.3em;
}

.content-container::-webkit-scrollbar-track {
  background: none;
}

.content-container::-webkit-scrollbar-thumb {
  background-color: #3b414d;
  border-radius:  10px;
}

.content-container::-webkit-scrollbar-thumb:hover {
  background: white;
}