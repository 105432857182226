.wrapper {
  display: flex;
  height: 60px;
  border-radius: 10px;
  width: 100%;

}

.input {
  border: none;
  outline: none;
  background: none;
  padding: 20px;
  color: white;
  font-size: 16px;
  width: 100%;
}

.btn {
  position: relative;
  font-size: 16px;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.btn:hover {
  cursor: pointer;
  background-color: #15181c;
}