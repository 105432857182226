.sidebar {
  position: relative;
  width: 300px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1;
  transition: 0.5s;
}

.sidebar.active {
  left: 0;
}