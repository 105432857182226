.historyListStyleCss::-webkit-scrollbar {
  width:  0.3em;
}

.historyListStyleCss::-webkit-scrollbar-track {
  background: none;
}

.historyListStyleCss::-webkit-scrollbar-thumb {
  background-color: #3b414d;
  border-radius:  10px;
}

.historyListStyleCss::-webkit-scrollbar-thumb:hover {
  background: white;
}